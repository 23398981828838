import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import styles from './firmware.mod.scss';
import * as showToastAction from '@modules/toasts/features/toastsSlice';
import { TOAST_TYPE } from '@modules/toasts/features/constants';
import ButtonV2 from '../../../resources/components/buttons/buttonV2';
import * as firmwareSlice from '@sections/codit/features/firmwareSlice';
import FirmwareModals from '@sections/codit/components/firmwareModals';
import LinearProgress from '@mui/material/LinearProgress';
import { flash } from '@sections/codit/features/install';
import { installState } from '@sections/codit/features/constants';
import { v1 as uuidv1 } from 'uuid';

const Firmware = (props) => {
  const dispatch = useDispatch();
  const manifestRobot = 'https://educablocks-staging-space-v1.sfo2.digitaloceanspaces.com/codit/manifest_robot.json';
  const manifestBoard = 'https://educablocks-staging-space-v1.sfo2.digitaloceanspaces.com/codit/manifest_board.json';
  const [installModalOpen, setInstallModalOpen] = useState(false);
  const [installStep, setInstallStep] = useState(0);
  const [installing, setInstalling] = useState(false);
  const [device, setDevice] = useState('');

  const [isSupported, setIsSupported] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);

  const firmwareState = useSelector(firmwareSlice.selectFirmware);
  const portList = firmwareState.portList;
  const manifest = firmwareState.manifest;
  const manifestFiles = firmwareState.manifestFiles;
  const portListLength = portList.filter((p) => p.port).length;
  
  // console.log('========================', isSupported, isAllowed, portList[installStep]);

  useEffect(() => {
    setIsSupported('serial' in navigator);
    setIsAllowed(window.isSecureContext);
  }, []);

  useEffect(() => {
    if (manifest.builds && manifest.builds[0]?.parts?.length > 0) {
      dispatch(firmwareSlice.downloadManifestFiles(manifest.builds[0].parts));
    }
  }, [JSON.stringify(manifest)]);

  useEffect(() => {
    if (firmwareState.error) {
      props.showToast({
        title: 'Error',
        message: firmwareState.error,
        type: TOAST_TYPE.ERROR,
        options: { autoClose: 7000 }
      });
    }
  }, [firmwareState.error]);

  useEffect(() => {
    if (installing) {
      if(firmwareState.installState === installState.FINISHED) {
        setInstallStep(installStep + 1);
        dispatch(firmwareSlice.setInstallState(installState.IDLE));
      } else if (firmwareState.installState === installState.IDLE) {
        install();
      } else if (firmwareState.installState === installState.ERROR) {
        dispatch(firmwareSlice.setPortError(portList[installStep].button, firmwareState.error));
        setInstallStep(installStep + 1);
        dispatch(firmwareSlice.setInstallState(installState.IDLE));
      }
    }
  }, [firmwareState.installState]);

  const install = () => {
    if (portList.length && manifestFiles.length && firmwareState.installState === installState.IDLE) {
      if (installStep < portList.length) {
        setInstalling(true);
        if (portList[installStep].port) {
          dispatch(
            flash(
              portList[installStep].button,
              portList[installStep].port,
              firmwareState.manifestFiles,
              firmwareState.manifestFilesTotalSize,
            )
          );
  
          props.showToast({
            title: 'Instalando',
            message: 'La instalación ha comenzado. Por favor espera.',
            type: TOAST_TYPE.INFO,
            options: { autoClose: 3000 }
          });
        } else {
          dispatch(firmwareSlice.setInstallState(installState.FINISHED));
        }
      } else {
        // TODO: if there aren't errors, clear ports to start another batch
        // dispatch(firmwareSlice.clearPorts());
        setInstalling(false);
        setInstallStep(0);
        props.showToast({
          title: 'Instalando',
          message: 'No hay más dispositivos en la lista.',
          type: TOAST_TYPE.SUCCESS,
          options: { autoClose: 3000 }
        });
      }
    }
  }

  const disconnect = (buttonId = '1') => {
    dispatch(firmwareSlice.removePort(buttonId));
    props.showToast({
      title: 'Error',
      message: `Dispositivo ${buttonId} desconectado`,
      type: TOAST_TYPE.ERROR,
      options: { autoClose: 3000 }
    });
  }

  const askPortPermissions = async () => {
    try {
      await navigator.serial.requestPort();
      props.showToast({
        title: 'Puerto vinculado',
        message: 'El puerto se ha vinculado correctamente. Ya se puede usar.',
        type: TOAST_TYPE.SUCCESS,
        options: { autoClose: 3000 }
      });
    } catch(err) {
      props.showToast({
        title: 'Error',
        message: 'No se seleccionó ningún dispositivo.',
        type: TOAST_TYPE.ERROR,
        options: { autoClose: 3000 }
      });
    }
  }
  const connect = async () => {
    // const buttonId = uuidv1();
    // let port = undefined;
    // try {
    //   port = await navigator.serial.requestPort();

    //   port.open({ baudRate: 115200 }).then(() => {
    //     port.addEventListener("disconnect", () => { disconnect(buttonId); });
    //     dispatch(firmwareSlice.addPort(buttonId, port));
    //   }).catch((err) => {
    //     console.log('========================ERROR ', err);
    //     props.showToast({
    //       title: 'Error',
    //       message: (err.message.includes('The port is already open')) ? 'El dispositivo ya está conectado' : err.message,
    //       type: TOAST_TYPE.ERROR,
    //       options: { autoClose: 3000 }
    //     });
    //     return;
    //   });
    // } catch(err) {
    //   props.showToast({
    //     title: 'Error',
    //     message: 'No encontramos dispositivos enchufados al puerto USB',
    //     type: TOAST_TYPE.ERROR,
    //     options: { autoClose: 3000 }
    //   });
    // }
    await clearPortList();
    const ports = await navigator.serial.getPorts();
    ports.map((port) => {
      console.log('==================PORT ', port.getInfo());
      const buttonId = uuidv1();
      port.open({ baudRate: 115200 }).then(() => {
        port.addEventListener("disconnect", () => { disconnect(buttonId); });
        dispatch(firmwareSlice.addPort(buttonId, port));
      }).catch((err) => {
        console.log('========================ERROR ', err);
      });
    });
    if (!ports.length) {
      props.showToast({
        title: 'Error',
        message: 'No encontramos dispositivos enchufados al puerto USB. Tal vez necesiten ser vinculados.',
        type: TOAST_TYPE.ERROR,
        options: { autoClose: 5000 }
      });
    }
  }

  const checkDevice = (selectedDevice = '') => {
    if (selectedDevice && !installing) {
      setDevice(selectedDevice);
      dispatch(firmwareSlice.downloadManifest((selectedDevice === 'robot') ? manifestRobot : manifestBoard));
    }
  }

  const clearPortList = async () => {
    setInstalling(false);
    setInstallStep(0);
    portList.map(async (port) => {
      if (port.port && port.port.readable) {
        await port.port.close();
      }
    });
    dispatch(firmwareSlice.clearPorts());
  }

  return (
    <div className={styles.firmware}>
      <div className={styles.title}>
        <img alt="logo" src="/images/codit/codit.png" />
      </div>
      <div className={styles.subtitle}>Seleccionar el dispositivo</div>

      <div className={styles.selector}>
        <div className={`${styles.item} ${styles.board} ${(device === 'board') ? styles.boardSelected : ''}`} onClick={() => checkDevice('board')}>
          <img alt="check" className={styles.check} src="/images/codit/circularCheck.svg" />
          <img alt="tablero" src="/images/codit/tablero.png" /> Tablero
        </div>
        <div className={`${styles.item} ${styles.robot} ${(device === 'robot') ? styles.robotSelected : ''}`} onClick={() => checkDevice('robot')}>
          <img alt="check" className={styles.check} src="/images/codit/circularCheck.svg" />
          <img alt="tablero" src="/images/codit/robot.png" /> Robot
        </div>
      </div>

      {(isSupported && isAllowed) ? (
        <>
          {portList.map((port, i) => {
            return (
              (port.button) ? (
                <div className={`${styles.deviceList} ${(port.error || !port.port) ? styles.error : ''}`} key={`key-${port.button}`}>
                  <div className={styles.progressText}>
                    {`Dispositivo ${i+1}`}
                    {/* <span>{(installStep === i) ? `[${firmwareState.installState}]` : ((port.error) ? `[${port.error}]` : ((installStep > i) ? ((port.error) ? `[${port.error}]` : '[Finalizado]') : ('')))}</span> */}
                    <div>
                      <span>{(installStep === i && port.port && !port.error) ? `[${firmwareState.installState}]` : ''}</span>
                      <span>{(installStep > i && !port.error) ? '[Finalizado]' : ''}</span>
                      <span>{(port.error) ? `[${port.error}]` : ''}</span>
                    </div>
                  </div>
                  <LinearProgress className={styles.progress} variant="determinate" value={port.progress || 0} />
                </div>
              ) : (
                <div className={styles.deviceList} key={`key-${Math.random()}`}><div className={styles.error}>Dispositivo desconectado</div></div>
              )
            )
          })}
          <div className={styles.actions}>
            <ButtonV2 className={styles.connectButton} onClick={() => connect()} disabled={!device || installing}>
              {/* {(portListLength > 0) ? 'Conectar otro dispositivo' : 'Conectar un dispositivo'} */}
              Conectar dispositivos
            </ButtonV2>

            {(portListLength > 0 && !installing) ? (
              <ButtonV2 className={styles.installButton} onClick={() => setInstallModalOpen(true)} disabled={!device || !manifestFiles.length}>Instalar</ButtonV2>
            ) : (null)}

            <ButtonV2 className={`${styles.clearButton} ${styles.permissionButton}`} onClick={askPortPermissions}>Vincular puertos</ButtonV2>
            {(portListLength > 0 && !installing) ? (
              <ButtonV2 className={styles.clearButton} onClick={clearPortList}>Borrar lista</ButtonV2>
            ) : (null)}
          </div>
        </>
      ) : (
        <div>No soportado</div>
      )}
      <FirmwareModals
        className={'success'}
        title={'Instalar firmware'}
        action={install}
        manifest={manifest}
        onClose={() => setInstallModalOpen(false)}
        active={installModalOpen && manifest.name}
      />

      <div className={styles.footer}>
        <div className={styles.logo}><img alt="logo" src="/images/codit/codit.png" /> | Powered by &nbsp;<span className={styles.accent}>Educabot</span></div>
        <div><sup>&reg;</sup> 2023 Educabot</div>
      </div>
    </div>
  );
};


const mapDispatchToProps = (dispatch) => {
  return {
    showToast: (params) => dispatch(showToastAction.showToast(params)),
  }
}

export default connect(null, mapDispatchToProps)(Firmware);
